import React, { useState } from "react"
import StandardLayout from "../layouts/standard"
import ContactForm from "../components/contact-form"
import coverImage from "../images/contactuscover.png"
import phoneIcon from "../images/contact/phone.svg"
import markerIcon from "../images/contact/marker.svg"
import up from "../images/contact/up.svg"
import down from "../images/contact/down.svg"

export default function Contact(props) {
  return (
    <StandardLayout title="Contact Us">
      <div className="cover-cont">
        <img src={coverImage} alt="" />
      </div>

      <div className="cotact-cont">
        <div className="map-frame-cont">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13717.900052218944!2d76.80065!3d30.733156!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed18281538af%3A0x55ea1cf057a72c4a!2sSector%207-C%2C%20Sector%207%2C%20Chandigarh%2C%20160019!5e0!3m2!1sen!2sin!4v1621533811869!5m2!1sen!2sin"
            width="100%"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="contact-form-cont">
          <div className="contact-title">
            Contact.
            <br /> Get in touch
          </div>
          <div className="contact-subtitle">LEAVE US A MESSAGE</div>
          <ContactForm></ContactForm>
        </div>
      </div>

      <div className="numbers-container">
        <Contacts isOpen={true} />
        {/* <Contacts2 isOpen={true} /> */}
      </div>
    </StandardLayout>
  )
}

function Contacts({ isOpen = false }) {
  const [open, setOpen] = useState(isOpen)
  return (
    <div style={{ marginBottom: "50px" }}>
      <div className="contact-title">CONTACT US</div>
      <div className="contact-location-cont">
        <div className="contact-location">CORPORATE OFFICE</div>
        <div>
          <img
            src={open ? up : down}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(s => !s)}
          />
        </div>
      </div>
      {open && (
        <div className="contact-cont2">
          <div className="contact-address-cont">
            <img src={markerIcon} style={{ margin: "15px 0px" }} />
            <div className="contact-address">
              SCO 14, Sec 7C,
              <br /> Madhya Marg, Chandigarh,
              <br /> Chandigarh (UT), 160019
            </div>
          </div>
          <div className="contact-address-cont">
            <img src={phoneIcon} style={{ margin: "15px 0px" }} />
            <div className="contact-address">
              <b>For General</b>
              <br />
              +91-1725021555 | +91-1725021666
            </div>
          </div>
          <div className="contact-address-cont">
            <img src={phoneIcon} style={{ margin: "15px 0px" }} />
            <div className="contact-address">
              <b>For Domestic</b>
              <br />
              +91-7508009921 | +91-7508009972
            </div>
          </div>
          <div className="contact-address-cont">
            <img src={phoneIcon} style={{ margin: "15px 0px" }} />
            <div className="contact-address">
              <b>For International</b>
              <br />
              +91-7508009951 | +91-7508009991
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function Contacts2({ isOpen = false }) {
  const [open, setOpen] = useState(isOpen)
  return (
    <div style={{ marginBottom: "50px" }}>
      <div className="contact-title">INTERNATIONAL</div>
      <div className="contact-location-cont">
        <div className="contact-location">EUROPEAN REPRESENTATIVE</div>
        <div>
          <img
            src={open ? up : down}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(s => !s)}
          />
        </div>
      </div>
      {open && (
        <div className="contact-cont2">
          <div className="contact-address-cont">
            <img src={markerIcon} style={{ margin: "15px 0px" }} />
            <div className="contact-address">
              DR. FABRIZIO BECUCCI
              <br /> VEGA Rappresentanze Industriali, Corso Massimo D'Azeglio
              102, 10126 Torino ITALY
            </div>
          </div>
          <div className="contact-address-cont">
            <img src={phoneIcon} style={{ margin: "15px 0px" }} />
            <div className="contact-address">
              <b>For General</b>
              <br />
              +91-1725021555 | +91-1725021666
            </div>
          </div>
          <div className="contact-address-cont">
            <img src={phoneIcon} style={{ margin: "15px 0px" }} />
            <div className="contact-address">
              <b>For Domestic</b>
              <br />
              +91-7508009921 | +91-7508009972
            </div>
          </div>
          <div className="contact-address-cont">
            <img src={phoneIcon} style={{ margin: "15px 0px" }} />
            <div className="contact-address">
              <b>For International</b>
              <br />
              +91-7508009951 | +91-7508009991
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
